.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: black;
  width: 100vw;
  height: 3.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 50;
  padding-top: 1em;
  padding-bottom: 1em;
}

.header a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  line-height: 3.5rem;
}

.header a:hover,
.header a:active {
  background: rgba(0, 0, 0, 0.2);
}

.header a.logo {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.logo h1 {
  padding: 0 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
}

@media (max-width: 639px) {
  .logo h1 {
    display: none;
  }
}

.header nav a.active {
  background: rgba(0, 0, 0, 0.4);
}
.nav {
  display: flex;
  width: 30vw;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.hlink {
  flex: 1;
  min-width: 5px;
  text-align: center;
  white-space: nowrap;
}
@media only screen and (max-width: 1300px) {
  .nav {
    display: flex;
    width: 80vw;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-left: -100px;
  }
}
